











































import {Component, Vue} from "vue-property-decorator";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import PayrollService from "@/services/payroll/PayrollService";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import FileService from "@/services/FileService";
import {namespace} from "vuex-class";
import FileUpload from "@/components/common/FileUpload.vue";
import Workspace from "@/dto/auth/Workspace";
import {STATE_CATEGORY} from "@/dto/payroll/PayrollReportFilterOld";
import PayrollReportDTO from "@/dto/payroll/PayrollReportDTO";
import Form1042SDTO from "@/dto/payroll/Form1042SDTO";
import Upload1042SModal from "@/components/payroll/business/Upload1042SModal.vue";
import Workspaces from "@/state/Workspaces";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
  components: {FileUpload}
})
export default class BusinessForms1042S extends Vue {

  @AppModule.Action
  private startLoading!: () => void;

  @AppModule.Action
  private stopLoading!: () => void;

  @Auth.Getter
  private hasAdministrativePrivileges!: boolean;

  private message = "";

  private successful = true;

  private forms: Array<Form1042SDTO> = [];

  mounted() {
    this.loadForms();
  }

  loadForms() {
    this.message = "";
    this.startLoading();
    PayrollService.getForms1042S(this.counterpartyId).then(
        response => {
          this.forms = response.data;
          this.successful = true;
          this.stopLoading();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

  get counterpartyId() {
    return Number.parseInt(this.$route.params.counterpartyId);
  }

  onFilesUploaded(files: FileMetaDTO[], employerId: number, period: string) {
    this.startLoading();
    this.message = "";
    PayrollService.batchUploadReports(Workspaces.getCurrent.id, files.map(f => f.id), STATE_CATEGORY)
        .then(() => {
          this.loadForms();
        })
        .then(() => {
          this.stopLoading();
        })
  }

  addNewForm() {
    this.$modal.show(
        Upload1042SModal,
        {
          onFilesUploaded: () => this.loadForms(),
          counterpartyId: this.counterpartyId
        });
  }

  deleteDocument(document: PayrollReportDTO) {
    this.message = "";
    this.startLoading();
    PayrollService.deleteDocument(document.id, 'FORM_1042S').then(
        response => {
          this.message = "Deleted";
          this.successful = true;
          this.stopLoading();
          this.loadForms();
        },
        error => {
          this.successful = false;
          this.message = this.$t(ErrorLocalePathUtils.getPathForError(error)) as string;
          this.stopLoading();
        }
    );
  }

}
